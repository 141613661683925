import '@styles/globals.scss'
import { InferGetServerSidePropsType, NextPage } from 'next'
import type { AppProps } from 'next/app'
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NextIntlClientProvider } from 'next-intl'
import { useRouter } from 'next/router'

export type NextPageWithLayout<Props extends (args: any) => any> = NextPage<InferGetServerSidePropsType<Props>> & {
  getLayout?: (page: React.ReactElement, props: InferGetServerSidePropsType<Props>) => React.ReactNode;
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout<any>;
}

function App ({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect

  const getLayout = Component.getLayout ?? (page => page)

  const [queryClient] = React.useState(() => new QueryClient())

  const router = useRouter()

  return <QueryClientProvider client={queryClient}>
    <NextIntlClientProvider
      locale={router.locale}
      timeZone="Europe/Vienna"
      messages={pageProps.messages}
    >
      {getLayout(<Component {...pageProps} />, pageProps)}
    </NextIntlClientProvider>
  </QueryClientProvider>
}

export default App
